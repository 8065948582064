import React, { useState } from "react";

// import { AlertStatus } from "../../lib/enums";

const AlertContext = React.createContext(null);
AlertContext.displayName = "AlertContext";

const AlertProvider = ({ children }) => {
  const [alert, setAlert] = useState("");
  const [alertText, setAlertText] = useState("");

  return (
    <AlertContext.Provider
      value={{
        alert: alert,
        alertText: alertText,
        success: (text, timeout) => {
          setAlertText(text);
          setAlert("Success");
          setTimeout(() => {
            setAlert("");
            setAlertText("");
          }, timeout * 1000 || 5000);
        },
        error: (text, timeout) => {
          setAlertText(text);
          setAlert("Error");
          setTimeout(() => {
            setAlert("");
            setAlertText("");
          }, timeout * 1000 || 5000);
        },
        clear: () => {
          setAlert("");
          setAlertText("");
        },
      }}
    >
      {children}
    </AlertContext.Provider>
  );
};

export { AlertProvider };
export default AlertContext;
