import { useContext } from "react";

// Context
import AlertContext from "../../contexts/AlertContext";

// Custom styles
import "./alert.styles.scss";

const Alert = () => {
  const alert = useContext(AlertContext);
  if (alert.alert !== "") {
    return (
      <div
        className={`alert ${
          alert.alert === "Success" ? "alert-success" : "alert-danger"
        }`}
      >
        {alert.alertText}
      </div>
    );
  } else {
    return null;
  }
};

export default Alert;
